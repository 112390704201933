import React from "react";

function MA351() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    MA 35100 - Elementary Linear Algebra    
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"Systems of linear equations, finite dimensional vector spaces, matrices, determinants, eigenvalues and eigenvector 
                applications to analytical geometry."</i></p>

            <p>Difficulty: <span style={{color: "orange"}}><b>Medium</b></span></p>

            <p>
                MA 351 is a standard first course in linear algebra. However, unlike MA 265 (Linear Algebra), MA 351 is proof-oriented.
                While computational aspects of linear algebra are still discussed, most of class time is spent proving theorems and building an
                understanding of "why" as opposed to focusing on "how" to do linear algebra. This emphasis is reflected in homework assignments:
                MA 351 problem sets frequently ask you to prove or show that a particular fact is true, while MA 265 homework is almost focused 
                entirely on doing calculations. In my opinion, MA 351 is much more useful than MA 265 in learning linear algebra; while knowing
                how to do computations is useful, understanding the intuition and theorems will give you deeper insight into the
                primary ideas in linear algebra, allowing you to better apply it in computer science topics.
            </p>

            <p>
                Since MA 351 is considered an upper-level math class, the homework assignments, exams, and calendar are not standardized across
                sections, and the professor is free to come up with their own course structure and syllabus. I took MA 351 with Professor Chen, who
                had weekly homework assignments and 2 midterms with one final. All homework and exams consisted of primarily open-ended questions, with a
                few true/false sprinkled in between. The open-ended nature of MA 351 exams allows for partial credit, something that isn't possible with
                the multiple choice format in MA 265. 
            </p>

            <p>
                If you're deciding on whether to take MA 265 or MA 351 to satisfy the linear algebra requirement of the CS major, I would strongly recommend
                MA 351. You'll walk away from MA 351 with a better understanding of the subject compared to MA 265. If you end up choosing a CS track that makes
                heavy use of linear algebra like computer graphics or machine intelligence, MA 351 will also be very useful in the courses you'll have to take.
            </p>

            </div>
        </>
    );
}

export default MA351;