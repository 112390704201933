import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./MenuStyling.css";


function Menu() {
    return (
        <div>
            <Dropdown data-bs-theme="dark">
                <DropdownButton className="menu" id="dropdown-basic-button" title="Menu" >
                    <Dropdown.Item href="/"> Home</Dropdown.Item>
                    <Dropdown.Item href="/resume">Resume</Dropdown.Item>
                    <Dropdown.Item href="/courses">Courses + Reviews</Dropdown.Item>
                </DropdownButton>
            </Dropdown>
        </div>
    );
}

export default Menu;