import React from "react";

function CS182() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    CS 18200 - Foundations of Computer Science    
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"Logic and proofs; sets, functions, relations, sequences and summations; number representations; 
                counting; fundamentals of the analysis of algorithms; graphs and trees; proof techniques; recursion; 
                Boolean logic; finite state machines; pushdown automata; computability and undecidability."</i></p>

            <p>Difficulty: <span style={{color: "orange"}}><b>Medium</b></span></p>

            <p>
                Despite being labeled as a CS class, CS 182 is really a math class. In particular, it focuses on topics in
                discrete math that are most relevant in the study of computer science. 
            </p>

            <p>
                The equivalent of this course at other universities is sometimes regarded as the one of the hardest classes in
                the CS curriculum. While CS 182 isn't usually considered to be part of the most difficult undergraduate CS class at Purdue 
                (that honor goes to CS 381 - Algorithms, CS 352 - Compilers, and CS 354 - Operating Systems), it's still a tricky class nonetheless. It covers a 
                wide variety of topics in discrete math, ranging from logic, proofs, and number theory to algorithms and graphs. Assuming you didn't 
                do competition math in high school (e.g. AMC, AIME), you're probably seeing many of these ideas for the first time, which can be challenging initially. 
                For me, the hardest part was writing proofs. With proofs, there is no "formula" to construct them; the
                only way to learn it is to do as many problems as possible, gradually building up intuition on how to approach such problems.
            </p>

            <p>
               Your grade in CS 182 is based on weekly homework and exams (of which there are 2 midterms and 1 final), with the weight being skewed heavily
               towards the latter the semester I took it. The emphasis on exams heavily contributes to the difficulty of the course, as getting a below-average
               grade on just one will make it extremely difficult to bring your grade back up. Homework assignments in CS 182 must be typed, using any text editor
               you prefer. However, most students end up using Microsoft Word or LaTeX. I ended up learning and using LaTeX, which would be my recommendation for
               anyone taking this course. In STEM disciplines, LaTeX is often used to prepare documents, and some upper-level CS classes will only accept LaTeX files
               for homework submissions. As such, I would recommend learning how to use it sooner than later, and a quick way to do so is using it to do your homework.
            </p>

            <p>
                Despite its challenges, I found CS 182 to be an extremely useful and interesting class. In course content, I found the lectures
                on graphs to be the most intriguing, and I look forward to exploring it further in CS 251. Additionally, the emphasis on proofs helped me 
                develop mathematical "maturity", and I found myself doing better in the math classes I took alongside CS 182 once I began to understand them. 
                If you plan on taking upper-level math classes or math-oriented CS classes, CS 182 will provide an excellent foundation for success in them.  
            </p>

            </div>
        </>
    );
}

export default CS182;