import React from "react";
import "./FooterStyling.css";
import { SocialIcon } from "react-social-icons";

function Footer() {
    return(
        <div className="footer">
            <span>
                Anthony Wang, 2024
            </span>
            <div>
                <SocialIcon className="button" url="mailto:anthonyw40916@gmail.com" style = {{height: 25, width: 25}}/>
                <SocialIcon className="button" url="https://linkedin.com/in/anthony-wang-b40b09262" network="linkedin" style = {{height: 25, width: 25}}/>
                <SocialIcon className="button" url="https://github.com/surface-integral" style = {{height: 25, width: 25}}/>
                <SocialIcon className="button" url="https://www.instagram.com/not_anthony73/" network="instagram" style = {{height: 25, width: 25}}/>
            </div>
        </div>
    ); 
    
}

export default Footer;