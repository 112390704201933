import React from "react";
import {Link} from 'react-router-dom';



const Courses = () => {
    return (
        <div className="container">
            <main>
            <h1 style={{fontSize: 20, color: "rgb(90, 90, 90)", marginTop: "10px", marginBottom: "25px"}}>
                Below you'll find a list of courses I've taken thus far at Purdue, broken down by semester.
                Click on a course to read what I thought of it!
            </h1>

            <h2 style={{fontSize: 25, color: "rgb(90, 90, 90)"}}>
                Spring 2024
            </h2>
            <span>
                CS 252 - Systems Programming
            </span> <br />
            <span> 
                CS 381 - Introduction to the Analysis of Algorithms
            </span> <br />
            <span> 
                CS 373 - Data Mining and Machine Learning
            </span> <br />
            <span> 
                CS 307 - Software Engineering I
            </span> <br />
            <span>
                STAT 417 - Statistical Theory
            </span> <br />
            <p style={{color: "rgb(90, 90, 90)"}}>
                VIP 27920 - AI in Music (Research Project)
            </p>

            <h2 style={{fontSize: 25, color: "rgb(90, 90, 90)"}}>
                Fall 2023
            </h2>
            <span>
                CS 251 - Data Structures and Algorithms
            </span> <br />
            <span> 
                CS 250 - Computer Architecture
            </span> <br />
            <span> 
                CS 290AIB - AI Basics
            </span> <br />
            <span> 
                MA 341 - Foundations of Analysis
            </span> <br />
            <span>
                STAT 511 - Statistical Methods
            </span> <br />
            <p style={{color: "rgb(90, 90, 90)"}}>
                COM 217 - Science Writing and Presentation
            </p>

            <h2 style={{fontSize: 25, color: "rgb(90, 90, 90)"}}>
                Spring 2023 
            </h2>
            <Link to="/courses/cs182" className="link">CS 182 - Foundations of Computer Science</Link> <br />
            <Link to="/courses/cs240" className="link">CS 240 - Programming in C</Link> <br />
            <Link to="/courses/ma351" className="link">MA 351 - Elementary Linear Algebra</Link> <br />
            <Link to="/courses/ma416" className="link">MA/STAT 416 - Probability</Link> <br />
            <span>MA 290 (currently MA 26190) - Data Science Labs on Multivariable Calculus</span> <br />
            <span>CS 197 - Freshman Honors Seminar</span> <br />
            <p style={{color: "rgb(90, 90, 90)"}}>CS 101 - Digital Literacy</p>


            <h2 style={{fontSize: 25, color: "rgb(90, 90, 90)"}}>
                Fall 2022
            </h2>
            <Link to="/courses/cs180" className="link">CS 180 - Object Oriented Programming and Problem Solving</Link> <br />
            <Link to="/courses/ma261" className="link">MA 261 - Multivariate Calculus</Link> <br />
            <span>CS 193 - Tools </span> <br />
            <span>CHNS 313 - Reading and Writing Practice</span> <br />
            <span>CS 191 - Freshman Resources Seminar</span> <br />
            </main>
        </div>
    );
}

export default Courses;