import React from "react";

function CS180() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    CS 18000 - Problem Solving and Object-Oriented Programming    
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"Problem solving and algorithms, implementation of algorithms in a high level programming language, 
                conditionals, the iterative approach and debugging, collections of data, searching and sorting, 
                solving problems by decomposition, the object-oriented approach, subclasses of existing classes, 
                handling exceptions that occur when the program is running, graphical user interfaces (GUIs), data stored in files, 
                abstract data types, a glimpse at topics from other CS courses."</i></p>

            <p>Difficulty: <span style={{color: "green"}}><b>Easy</b></span></p>

            <p>
                CS 180 is a standard "intro to CS" course that you'll find at virtually every other college.
                It's taught in Java, starting from basic concepts like variables and loops before
                moving on to the objected-oriented programming (OOP) paradigm. Some basic data structures like 
                arrays and ArrayLists are also covered. Towards the end, you also learn about File I/O, GUIs, 
                and networking.
            </p>

            <p>
                When I took it, there were programming assignments that started in your lab section and were 
                due by the next lab, giving you exactly one week to complete it. On top of that, there were also
                5 projects. Projects 1-3 were opened on Monday's with two weeks to complete them, with the next being released
                the moment the previous was due. Projects 4 and 5 are a bit different compared to 1-3, and I'll elaborate
                on them in a later paragraph.
            </p>

            <p>
                Exams are all open book and online, with two midterms and a final. The midterms consisted of programming
                questions similar to homework assignments, while the final was multiple choice. For each exam, there was
                a 24-hour timeslot in which you could begin and a 2-hour window to submit once started.
            </p>

            <p>
                Speaking from the perspective of someone with previous programming experience, CS 180 was a relatively
                easy course. The majority of the course ended up being a review, although the topics discussed towards
                the end were new to me. Even then, I still found the new material to be quickly understandable. The programming
                assignments and projects 1-3 were of reasonable difficulty, and I could complete all of them within 3-4 days of
                starting. As long as you don't wait until the last minute to begin, there shouldn't be too much of an issue. 
            </p>

            <p>
                However, if you're someone with no prior exposure to programming, CS 180 could potentially be a challenging course
                due to how quickly it moves. When I took AP Computer Science A in high school, the material was covered over two semesters,
                while CS 180 covers all of AP CSA and more in a single semester. For those with no programming experience, I would consider
                checking out Purdue's <a href="https://www.cs.purdue.edu/undergraduate/bridge/index.html" className="link">Bridge</a> program.
            </p>

            <p>
                The most challenging (and interesting) part of CS 180 is Projects 4-5. In comparison to Projects 1-3, where you're expected
                to work by yourself, you are randomly placed in a group with 3-5 people and given a long software engineering task to complete over
                three weeks. Project 5 is a continuation of what you started in Project 4, adding additional features that incorporate new topics
                learned in lecture. These projects are a (rough) simulation of how software engineering projects work in the real world, so you're
                expected to meet with your group mates and work together to complete the project. Even with multiple people working, you'll probably
                end up putting more time into the project than all the previous ones due to its complexity.
            </p>

            <p>
                Given that Projects 4-5 are a group effort, it can either be a difficult but rewarding experience or the worst weeks of your semester. 
                I was fortunate to be put in a group where everyone pulled their own weight, but I've heard horror stories from others that were placed
                with students that did not do any work, resulting in them pulling all-nighters in an attempt to complete the project. While measures are put
                in place to penalize those that don't contribute, there will inevitably be people who don't care. Either way, you're bound to get something
                out of these projects: if you have good group mates, it'll be a great learning experience, and if you end up doing everything by yourself,
                at least you'll have a good story to tell to your friends and job recruiters.
            </p>

            <p>
                Overall, I found CS 180 to be a fun course to take and a great way to begin the CS major. Even if you don't learn that much new material,
                you'll have the opportunity to meet your classmates and make new friends along the way. Additionally, having an "easy" CS course for the first semester
                will also help in allowing you to get familiar with being in college. Enjoy CS 180 while it lasts, as it will probably be the only "easy"
                CS class you take at Purdue.
            </p>
            </div>
        </>

    );
}

export default CS180;