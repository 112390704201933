import React from "react";
import "./HomeStyling.css";
import { SocialIcon } from 'react-social-icons';

function Home() {
    const korngold = <a href="https://www.youtube.com/watch?v=lcGEGl5bdbk" className="link"> 
        Korngold's Violin Concerto in D Major
    </a>
    const laValse = <a href="https://www.youtube.com/watch?v=TMSgWhIENSk" className="link">
        Ravel's La Valse
    </a>

    return (
        <div className='container'>
            <h1 id="main-heading">
                Hello!
            </h1>
            <div>
                <SocialIcon className="button" url="mailto:anthonyw40916@gmail.com" />
                <SocialIcon className="button" url="https://linkedin.com/in/anthony-wang-b40b09262" network="linkedin"/>
                <SocialIcon className="button" url="https://github.com/surface-integral" />
                <SocialIcon className="button" url="https://www.instagram.com/not_anthony73/" network="instagram"/>
            </div>
            <p className="main-text">
                My name is Anthony Wang. I'm currently a rising sophomore at
                Purdue University in West Lafayette, Indiana studying computer science with concentrations
                in machine intelligence and software engineering. I am also minoring in mathematics, as I find
                the subject highly interesting in addition to it being very important in computer science.  
            </p>

            <p className="main-text">
                Within computer science, my main interests are in artificial intelligence/machine learning, software engineering,
                and theoretical CS. Additionally, I also enjoy learning more about probability, statistics, and linear algebra, 
                as these subjects are used extensively in AI and ML. 
            </p>

            <p className="main-text">
                In my free time, I enjoy playing the violin (fun fact: I've been playing for over 15 years) and am also an avid
                listener of classical music. Currently, my favorite pieces are {korngold} and {laValse}. However, my interests can 
                change pretty quickly, so I'll probably be updating this section relatively frequently.
            </p>

            <p className="main-text">
                On the top of the page, you'll see a few links where you can view my resume and as well as a list of courses I've taken
                at Purdue. I'll most likely be updating this site in the future, so be sure to check back occasionally for any new additions!
            </p>
        </div>  
    );
}

export default Home;