import resume from "./Wang_Anthony_Resume.jpg";
import Button from 'react-bootstrap/Button';
import file from "./downloads/Wang_Anthony_Resume.pdf";

function Resume() {
    return (
        <>
        <div style={{display: "flex", justifyContent: "center", margin: "3% 3%", borderRadius: "12px"}}>
            <a href={file} download> 
                <Button variant="outline-primary" size="lg">Download Resume</Button>{' '}
            </a>
        </div>
        <div style={{display: "flex", justifyContent: "center", marginBottom: "8%"}}>
            <img src={resume} alt="Resume for Anthony Wang"
            style={{width: "auto", height: "auto", maxHeight: "80%", maxWidth: "80%", padding: "0 10 1 3rem"}}></img>
        </div>
        </>
    );
}

export default Resume;