import React from "react";

function MA261() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    MA 26100 - Multivariate Calculus   
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"Planes, lines, and curves in three dimensions. Differential calculus of several variables; 
                multiple integrals. Introduction to vector calculus."</i></p>

            <p>Difficulty: <span style={{color: "green"}}><b>Easy</b></span></p>

            <p>
                MA 261, often referred to as "Calculus 3", is essentially an extension of concepts in Calculus 1 and 2
                to higher-dimensional spaces. Most of the course is spent studying two-variable functions that result in a surface
                in 3-D space when graphed. There's also some lessons with three variable functions discussed within the context of
                triple integrals. Higher dimensions beyond three variables are ignored, but the most of the concepts taught can be
                generalized to additional dimensions.
            </p>

            <p>
                Your grade in MA 261 is based on homeworks, quizzes, and exams, with exams taking up the majority of the weight.
                On a typical week, there were two homework assignments, all online through a website called MyLab Math with questions
                designed by the publisher for the textbook used in the class. Each assignment had around 10 questions, and I
                was able to complete most of them within 2 hours. Additionally, there are also weekly quizzes that are given on paper 
                during recitation sections. The graduate TA leading my section had 15 minute quizzes at the end of each recitation, usually
                with 2-4 questions each. 
            </p>

            <p>
                The most difficult aspect of MA 261 (and other lower-division math courses at Purdue) is the exams, which also 
                just happens to take up the most weight in grading. Due to the vast amount of students taking this class each semester (there were
                a few thousand students when I took it), both midterm exams and the final are multiple choice to faciliate grading.
                As a result, you have to extremely careful when answering questions: even the smallest mistake, like forgetting a negative sign,
                would cause you to lose all credit on the question even if the rest of your work is correct. While this method of grading is annoying
                (and not the best way to test your understanding of math), you should be fine as long as you remain focused and understand what you're
                doing (for reference, I got a 100% on both midterms and only got one question wrong on the final).
            </p>

            <p>
                Personally, I found the topics discussed in MA 261 to be quite interesting, especially towards the end where you learn vector calculus.
                Conceptually, the course content wasn't overly challenging, since the underlying concepts of derivatives and integrals
                remain the same. As long as you have a solid understanding of Calculus 1 and 2, extending concepts from those courses to higher dimensions
                should feel somewhat natural and intuitive, almost as if you could come up with them by yourself. 
            </p>
        </div>
    </>

    );
}

export default MA261;