import React from "react";

function CS240() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    CS 24000 - Programming in C   
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"The UNIX environment, C development cycle, data representation, operators, program structure, recursion, macros, C preprocessor, pointers and addresses, 
                dynamic memory allocation, structures, unions, typedef, bit-fields, pointer/structure applications, UNIX file abstraction, file access, low-level I/O, 
                concurrency."</i></p>

            <p>Difficulty: <span style={{color: "orange"}}><b>Medium</b></span></p>

            <p>
                CS 240 is a bit like CS 180 in that it is focused on teaching how to program in a particular language. If you're a CS major, CS 180
                and CS 240 are the only required classes whose topic is learning a programming language.
            </p>

            <p>
                Given that CS 240 has CS 180 as a prerequisite, it assumes that you're proficient in the basics of programming and jumps directly into
                the quirks of C. For the first few weeks, the lecture material and homework assignments will not be too difficult, allowing you to get
                used to the basics of C. Topics discussed here include file I/O, structs, and the layout of memory.
            </p>

            <p>
                However, the course becomes considerably harder approximately 1/3 of the way through with the introduction of pointers. One
                feature of C that sets it apart from languages like Java or Python is the ability to manage memory. Furthermore, C will assume that you
                know what you're doing, so there is no automatic memory management (also known as "garbage collection"). This makes C extremely useful
                for interacting with hardware (something that's discussed towards the end of the course) and allows C programs to run much quicker since there 
                is no extra processing overhead. 
            </p>

            <p>
                On the other hand, improper memory management or careless mistakes will make programming in C a very frustrating
                experience. One of the most common errors you'll encounter doing homework involving dynamic memory allocation is the classic segfault,
                which is shown when a memory leak occurs. Make sure to be extremely careful when writing each line of code and pay attention to lectures
                on memory management, or you'll end up spending hours trying to figure out why your program isn't working before realizing that you 
                neglected to allocate memory for the null terminator in a string (yes, I have done this before).
            </p>

            <p>
                Grading in CS 240 is similar to CS 180, with the majority of your grade coming from homework and exams. There are no projects in CS 240
                when I took it, so if you're in luck if you hated doing them in CS 180. However, you're required to do all programming inside the terminal
                without the aid of an IDE, so you'll have to manually type up every line of code. Homework is also considerably more complex than those in CS
                180, given that you should be fairly proficient in programming when taking this class. Additionally, all exams are on paper and contain primarily
                coding questions, meaning that you'll have to handwrite code. These differences will make CS 240 homework and exams more difficult and time-
                consuming compared to CS 180, so be prepared to work harder in this class if you were slacking off in 180.
            </p>

            <p>
                While challenging, I found many topics taught to be quite useful and applicable beyond the C programming language. For example, pointers, while
                only directly accessible to the programmer in C, are embedded into virtually every programming language and are an important of many data structures.
                Additionally, knowing C gives you the ability to interact with hardware, allowing for the creation of interesting projects. Even if you end up not using
                C outside of classes, it's still a highly useful language to know, and the knowledge gained in this class will help make you a better computer scientist.
            </p>

            </div>
        </>
    );
}

export default CS240;