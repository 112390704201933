import React from "react";

function MA416() {
    return (
        <>
            <div style={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "40px", 
            marginBottom: "30px", marginLeft: "10%", marginRight: "10%", flexWrap: "wrap"}}>
                <h1 style={{fontSize: "25px"}}>
                    MA/STAT 41600 - Probability   
                </h1>
            </div>

            <div style={{display: "flex", flexDirection: "column", textAlign: "left", marginLeft: "7%", 
            marginRight: "7%", marginBottom: "10%", maxWidth: "120ch", fontFamily: "verdana, sans-serif", 
            color: "rgb(90, 90, 90)"}}>
            <p>Official description from Purdue Course Catalog:</p>
            <p><i>"An introduction to mathematical probability suitable as preparation for actuarial science, statistical theory, and mathematical 
                modeling. General probability rules, conditional probability and Bayes theorem, discrete and continuous random variables, moments 
                and moment generating functions, joint and conditional distributions, standard discrete and continuous distributions and their 
                properties, law of large numbers and central limit theorem. Prerequisite: multivariate calculus."</i></p>

            <p>Difficulty: <span style={{color: "orange"}}><b>Medium</b></span></p>

            <p>MA 416 can be loosely split up into three sections, each focusing on a major part of probability</p>

            <p>
                The first part of the course focuses on discrete probability. If you've taken any kind of course on probability and statistics in high school, such as AP Statistics, 
                chances are that you've likely seen most of the topics taught in this part of the course. As such, this part of the course will probably be a review for most people, although
                many concepts are presented with a higher level of mathematical rigor than a course like AP Statistics. However, this additional level of rigor shouldn't bee to difficult to 
                integrate with existing knowledge.
            </p>
            
            <p>
                The second part of the course is where the level of difficulty ramps up considerably. This section introduces continuous probability
                distributions, meaning that calculus needs to be used to compute probabilities. Make sure to review integration techniques from Calculus 2, 
                especially integration by parts. The addition of calculus means that homework assignments will also take longer to do, with many questions resulting in the 
                evaluation of an integral. If your integration abilities are rusty, problem sets can become extremely tedious. Most topics in this section will likely be new, 
                and combined with the harder homeworks, you'll probably have to spend more time on this course than you previously did. 
            </p>

            <p>
                The last part of the course focuses on inequalities in probability, as well as the law of large numbers and the central limit theorem. Similar to the first
                part of the course, you've probably heard of some of these ideas before. However, they are also explored with a high level of mathematical rigor that involving
                proofs that utilize knowledge from the second part of the course. Despite this, applying these concepts to problems is relatively easy, and in my experience, this 
                section is the course is the easiest.
            </p>

            <p>
                Like MA 351, MA 416 is an upper-level math course, so professors have the freedom to design their own course structure. I took this class with Professor Noack,
                who assigned problem sets for each chapter along with 2 midterms and a final. Both the problems sets and exams were of medium difficulty, and I didn't find them
                to be unreasonably challenging. The problem sets can take some time due to how many questions each had, but they were quite manageable as long as you started early.
            </p>

            <p>
                I took this course because of my interests in machine learning and artificial intelligence, which use a lot of probability theory. Indeed, many concepts in ML became
                much clearer and understandable after taking this class. If you're doing the machine intelligence track, I highly recommend taking this class over STAT 512 
                (Applied Regression Analysis). Probability is absolutely essential in understanding ML theory, and knowing probability will help in understanding regression since 
                regression can be framed as a probability/statistics question.
            </p>
            </div>
        </>
    );
}

export default MA416;