
import './App.css';
import Courses from "./components/Courses.js";
import Home from './components/Home';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Resume from './components/Resume';
import CS180 from "./components/course-pages/CS180";
import CS182 from "./components/course-pages/CS182";
import CS240 from "./components/course-pages/CS240";
import MA261 from "./components/course-pages/MA261";
import MA351 from "./components/course-pages/MA351";
import MA416 from "./components/course-pages/MA416";

import { Route, Routes} from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <Navbar />
      
      <Routes>
        <Route path="/courses" element = {<Courses />} />
        <Route path="/" element = {<Home />}/>
        <Route path="/resume" element = {<Resume />} />
        <Route path="/courses/cs180" element={<CS180 />}/>
        <Route path="/courses/ma261" element={<MA261 />}/>
        <Route path="/courses/cs182" element={<CS182 />}/>
        <Route path="/courses/cs240" element={<CS240 />}/>
        <Route path="/courses/ma351" element={<MA351 />}/>
        <Route path="/courses/ma416" element={<MA416 />}/>
      </Routes>
      
      <Footer />
    </div>
  );
}

export default App;
