import React from "react";
import "./NavStyling.css";
import { Link } from 'react-router-dom';
import Menu from "./Menu";


export default function Navbar() {
    return (
        <div>
            <nav className="nav">
                <div>
                    <Link to="/" className="title">
                        $anthony.wang
                    </Link>
                </div>
                <ul>
                    <Menu />
                    <Link to="/" className="nav-item">Home</Link>
                    <Link to="/resume" className="nav-item">Resume</Link>
                    <Link to="/courses" className="nav-item">Courses + Reviews</Link>
                </ul>
            </nav>
        </div>
    );
};